$mainFont: 'Gelasio',
serif;
$bold: bold;
$five500: 500;
$fontStyle: normal;
$whiteText: #FFFFFF;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

.home-review-header {
  font-family: $mainFont;
  font-style: $fontStyle;
  font-weight: 500;
  font-size: 2em;
  color: #081F32;
}

.home-reviews-btn {
  color: black;
  height: 3em;
  width: 12em;
  border: none;
  text-align: center;
  background: #fffa9e;
  outline: 0 !important;
  @include border-radius(0.8em);
}

.home-reviews-btn:hover {
  background: #1B75BB;
  color: white;
}

@media screen and (min-width: 1280px) {
  .home-discounts {
    display: flex;
    width: 100%;
    margin: 15px auto 0 auto;
    justify-content: space-between;
  }

  .home-review-header-container {
    display: flex;
    margin-top: 4.5em;
    justify-content: space-between;
  }

  .home-reviews {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2em;
  }

  .home-review-text1 {
    text-align: justify;
    width: 80%;
  }

  .home-mobile-all-directions-btn {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1280px) {
  .home-discounts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .home-review-header-container {
    display: flex;
    margin-top: 4.5em;
    justify-content: space-between;
  }

  .home-reviews {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 4.5em;
  }

  .home-mobile-all-directions-btn {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .home-discounts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .home-review-header-container {
    display: flex;
    margin-top: 4.5em;
    justify-content: space-between;
  }

  .home-reviews {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 4.5em;
  }

  .home-mobile-all-directions-btn {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

@media screen and (max-width: 768px) {
  .home-discounts {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 90%;
    align-self: center;
  }

  .home-review-header-container {
    display: flex;
    margin-top: 2.5em;
    flex-direction: column;
  }

  .home-review-header {
    text-align: center;
  }

  .home-reviews {
    display: flex;
    flex-direction: column;
    margin: 1em 5% 0 5%;
  }

  .home-reviews-btn {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .home-mobile-all-directions-btn {
    margin-left: 5%;
    height: 3em;
    width: 12em;
    margin-top: 1.5em;
    border: none;
    text-align: center;
    background: #fffa9e;
    color: black;
    @include border-radius(0.31em);
  }

  .home-mobile-all-directions-btn:hover {
    color: $whiteText;
    background: #1B75BB;
  }
}
