$mainFont: 'Gelasio',
serif;
$fontStyle: normal;

  .wrap-review {
    display: flex;
    justify-content: center;
    padding: 20px 60px;
  }

  .align-center {
    text-align: center;
  }

  .add-textarea {
    max-width: 800px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #1B75BB;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
  }

  .field-style {
    width: 60vw;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #1B75BB;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
  }

  .bottom-indent-small {
    margin-bottom: 10px;
  }

  .shortened-width-date {
    max-width: 20vw;
    margin: 10px 0px;
  }

  .form-ratings {
    padding: 10px 0px;
  }

  .background-white {
    background-color: white;
  }

  .photo {
    object-fit: cover;
    border-radius: 25px;
    margin: 10px 0px;
  }

  .remove-button {
    border: 1px solid #1B75BB;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
  }

  .rating-label {
    padding-top: 10px;
  }

  .form-review {
    display: flex;
    flex-direction: column;
  }

  .img-collection {
    display: flex;
    flex-wrap: wrap;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .bottom-indent {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1280) {
    .wrap-review {
      padding: 20px 90px;
    }
  }

  @media screen and (max-width: 1000px) {
    .wrap-review {
      padding: 20px 60px;
    }
  }

  @media screen and (max-width: 780px) {
    .wrap-review {
      padding: 20px 40px;
    }
  }

  @media screen and (max-width: 560px) {
    .field-style {
      width: 100%;
    }
  }