header.tour-infoPage {
  min-height: 65vh;
  align-items: center;
  justify-content: center;
}

.TourInfoPage-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  max-width: 80vw;
}

.tour-btn {
  background: rgba(255, 250, 158, 1);
  border-radius: 10px;
  font-size: 1.4em;
  padding: 8px 13px;
  color: black;
  text-decoration: none;
}

.tour-btn:hover {
  background: #1B75BB;
  color: #FFFFFF;
}

.TourInfoPage_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content_img {
  display: flex;
  width: 100%;
  height: 30vw;
}

.content_title {
  font-size: 3em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

.content_text {
  font-size: 1.5em;
  margin-top: 1em;
  width: 80vw;
  text-align: justify;
}

.media-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .content_img {
    width: 100%;
    height: 50vw;
  }
}

@media (max-width: 780px) {
  header {
    min-height: 50vh;
  }

  .content_img {
    width: 100%;
    height: 50vw;
  }

  .content_title {
    font-size: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 80vw;
  }

  .content_text {
    font-size: 1em;
    width: 90vw;
  }
}