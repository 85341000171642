  .content_unit_title {
    margin: 15px 0;
    color: #333;
    padding: 0;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
  }
  
  .content_unit_text {
    text-align: justify;
    margin: 0 0 25px;
    padding: 0;
    font-size: 1.5em;
    font-family: "Gelasio", serif;
  }