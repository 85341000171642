.tour_direction_unit {
    position: relative;
    margin:  0 0.5em 0.5em;
    z-index: 1;
    opacity: 0.6;
  }

  .adminMainImg {
    width: 60vw;
  }

  .blueBorder {
    border: solid 4px #007aff;
    border-radius: 16px;
    opacity: 1;
  
    .tour_direction_unit_text {
      bottom: -4px;
    }
  }

  .tour_direction_unit_text {
    background: rgba(1, 1, 1, 0.4);
    border-radius: 12px;
    height: 5vw;
    width: 100%;
    position: absolute;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
    font-size: 2.5vw;
    cursor: pointer;
  }

  .tour_direction_unit_main_img {
    height: 15vw;
    width: 15vw;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {  
    .tour_direction_unit_text {
      height: 2em;
      width: 100%;
      font-size: 1.5em;
      margin-bottom: -1px;
    }
  }

  @media screen and (max-width:1000px) { 
    .tour_direction_unit_main_img {
      display: none;
    }

    .tour_direction_unit {
      position: unset;
      display: flex;
      position: unset;
      align-items: center;
      justify-content: center;
      z-index: 0;
      width: 70px;
      padding: 8px 20px;
      opacity: 0.6;
    }

    .tour_direction_unit_text {
      background: rgb(255, 255, 255);
      border-radius: 10px;
      position: unset;
      text-align: center;
      color: #000000;
      z-index: 0;
      font-size: 14px;
      cursor: pointer;
    }

    .blueBorder {
      border: solid 3px #007aff;
      opacity: 1;
    }
}
