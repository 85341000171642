.review-super-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 8.25rem;

  .review-and-image-container {
    display: flex;
    align-items: center;
    margin-top: 2em;

    .review-form-flex {
      display: flex;
      flex-direction: column;
      min-width: 50%;

      .review-leave {
        font-size: 1.8rem;
        color: #212529;
        font-weight: 500;
      }

      .review-form {
        font-size: 1.5rem;

        label {
          display: block;
          padding-left: 0.15em;
          margin-top: 0.2rem;
        }

        .input-field, select:focus {
          border: 1px solid #1B75BB;
          padding: 0.625rem;
          box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
          border-radius: 10px;
          min-width: 97%;
        }

        .input-select{
          background-color: white;
        }

        .text{
          height: 46%;
        }

        .review-btn {
          background: rgba(32, 34, 41, 0.8);
          border: 1px solid rgba(0, 0, 0, 0.5);
          color: white;
          margin-top: 1rem;
          border-radius: 5px;
          width: 97%;
        }

        .input-style {
          width: 97%;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid #1B75BB;
          box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
        }
      }
    }

    .review-mountains {
      border-radius: 15px;
      object-fit: cover;
      height: 34.5rem;
    }
  }
}

  .error-color{
    color: red;
  }

@media screen and (min-width: 1100px) and (max-width: 1280px) {
  .review-super-container {
    padding-bottom: 7rem;
  }
  .review-and-image-container {
    margin: 2em 10vw 0 10vw;

    .review-form-flex {
      padding: 0 1rem;

      .input-field, .text {
        width: 90%;
      }

      .review-form select {
        width: 90%;
      }

      .review-btn {
        width: 100%;
      }
    }

    .review-mountains {
      object-fit: cover;
      margin-top: 5.1rem;
      height: 34.5rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .review-super-container {
    padding-bottom: 8.25rem;

    .review-and-image-container {
      margin: 2em 5vw 0 5vw;
      width: 100%;

      .review-form-flex {
        min-width: 100%;

        .review-leave {
          margin-top: 1em;
        }

        .review-form {
          .input-field, .text, .input-field, select:focus {
            width: 100%;
          }

          .review-btn {
            width: 100%;
          }
        }
      }

      .review-mountains {
        display: none;
        visibility: hidden;
      }
    }
  }
}