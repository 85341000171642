$whiteText: #FFFFFF;
$mainFont: 'Gelasio', serif;
$fontStyle: normal;
$bold: bold;

header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 150px 150px 20px 150px;
  background-size: cover;
  background-position: center;

  .header-img img{
    max-width: 40vw;
    border-radius: 35px;
    padding: 20px;
    max-height: 400px;
  }
}

section {
  padding: 20px 150px 0 150px;
}

@media screen and (max-width: 1080px) {
  header {
    flex-wrap: wrap;
    padding: 100px 30px 20px 30px;

    .header-img img{
      max-width: 100vw;
      width: 100vw;
      border-radius: 45px;
      padding: 30px;
      object-fit: cover;
      max-height: 500px;
    }
  }

  section {
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 100px 30px 10px 30px;

    .header-img img {
      max-width: 90vw;
      border-radius: 35px;
      padding: 20px;
      height: 80vw;
      object-fit: cover;
      max-height: 400px;
    }
  }
}

@media screen and (max-width: 480px) {
  header {
    flex-direction: column;
    padding: 80px 30px 30px 30px;

    .header-img {
      order: 1;
      padding-top: 30px;
    }
  }
}

