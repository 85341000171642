.wrapper-404{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
    h1{
      font-size: 3rem;
      margin: 0 5rem;
    }
}
