.planCard {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;

  &_title {
    font-size: 3em;
    text-align: center;
  }

  &_body {
    font-size: 1.5em;
  }

  .push {
    list-style: none;
  }

  .push li {
    position: relative;
    padding: 10px 0 10px 40px;
    cursor: pointer;
  }

  .push li:before {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #4F5151;
    content: "";
    left: 0;
    transition: .3s ease-in-out;
    top: 25px;
  }

  .push li:after {
    position: absolute;
    border-left: 1px dotted #4F5151;
    width: 1px;
    bottom: -12px;
    content: "";
    left: 3px;
    top: 48px;
  }

  .push li:hover:before {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .2)
  }


  .push li:last-child:after {
    content: none;
  }
}

@media screen and (max-width: 1000px) {
  .planCard {
    width: 90vw;

    &_title {
      font-size: 1.5em;
      text-align: center;
    }

    &_body {
      font-size: 1em;
    }

    .push li:before {
      top: 18px;
    }
  }
}

@media (max-width: 780px) {
  .planCard {
    .push {
      padding-left: 0.5rem;
    }

    .push li {
      padding: 10px 0 10px 20px;
    }
  }
}