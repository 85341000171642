body {
  overflow-x: hidden;
  font-family: 'Gelasio', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 100vh;
}

html {
  overflow-x: hidden;
  font-size: 16px;
  font-family: 'Gelasio', serif;
}

#root{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
