$whiteText: #FFFFFF;
$mainFont: 'Gelasio',
serif;
$fontStyle: normal;
$weight: 500;
$bold: bold;

.home-box {
  display: block;
  font-family: $mainFont;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px 50px 20px 20px;
  max-width: 60vw;

  .home-title {
    font-size: 3em;
    font-weight: 700;
    line-height: 1;
  }

  .home-body {
    line-height: 1;
    font-size: 1.5em;
    font-weight: 200;
    padding: 8px 0;
  }

  .home-btn {
    background: rgba(255, 250, 158, 1);
    border-radius: 5px;
    font-size: 1.4em;
    padding: 8px 13px;
    color: black;
    text-decoration: none;
  }

  .home-btn:hover {
    background: #1B75BB;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .home-box {
    padding: 15px;
  }


}

@media screen and (max-width: 480px) {
  .home-box {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .home-title {
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1;
  }
}