.About_page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About_page_img {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 30vh;
  max-height: 910px;
  -webkit-background-size: cover;
  background-size: cover;
  object-fit: cover;
}

.About_page_content {
  width: 80vw;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.About_page_teem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
}

.teem_unit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.armen-photo {
  height: 16em;
  width: 16em;
  background-size: cover;
  object-fit: cover;
  border-radius: 100%;
}

.teem_unit_text {
  text-align: center;
  font-size: 1em;
}

.teem_unit_title {
  padding-top: 0.5em;
  text-align: center;
  font-size: 1.5em;
}

@media screen and (max-width: 1000px) {
  .About_page_content {
    width: 80vw;
  }

  .About_page_teem {
    width: 80vw;
  }

  .teem_unit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40vw;
  }
}

@media (max-width: 780px) {
  .About_page_content {
    width: 90vw;
  }

  .About_page_teem {
    width: 90vw;
    flex-direction: column;
    align-items: center;
  }

  .teem_unit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
  }
}