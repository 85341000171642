.customModal {
  background: #e1e1e1;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
}

.modalText{
  margin: 1rem;
  text-align: center;
}

.book-super-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .book-and-image-container {
    display: flex;
    margin-top: 2em;
    max-width: 1160px;

    .book-form-flex {
      display: flex;
      flex-direction: column;
      align-items: center;

      .book-leave {
        font-weight: 600;
        font-size: 2em;
        color: #212529;
      }

      .book-form {
        font-size: 1.5em;

        label {
          display: block;
          padding-left: 0.15em;
        }
        .input-style {
          border: 1px solid #1B75BB;
          padding: 10px;
          box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
          border-radius: 10px;
          width: 97%;
        }

        .input-field, select:focus {
          border: 1px solid #1B75BB;
          padding: 10px;
          box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
          border-radius: 10px;
        }

        .input-select{
          background-color: white;
        }

        .error-color{
          color: red;
        }

        .book-btn {
          background: rgba(32, 34, 41, 0.8);
          border: 1px solid rgba(0, 0, 0, 0.5);
          color: white;
          margin-top: 1rem;
          border-radius: 5px;
        }
      }
    }

    .book-mountains {
      width: 36em;
      border-radius: 15px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .input-field, select {
    width: 97%;
    border-radius: 10px;
  }

  .book-form select {
    border: 1px solid #1B75BB;
    padding: 10px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
  }

  .book-btn {
    width: 97%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1280px) {
  .book-and-image-container {
    margin: 2em 10vw 0 10vw;

    .book-form-flex {
      padding: 0 1rem;

      .input-field {
        width: 40vw;
        padding: 10px;
      }

      .book-form select {
        width: 40vw;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #1B75BB;
        box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
      }

      .book-btn {
        width: 40vw;
      }
    }

    .book-mountains {
      width: 44vw;
      border-radius: 15px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1100px) {
  .book-and-image-container {
    margin: 2em 5vw 0 5vw;
    width: 90vw;
    display: flex;
    justify-content: space-between;
  }

  .book-mountains {
    display: none;
    visibility: hidden;
  }

  .input-field {
    width: 90vw;
  }

  .book-form select {
    width: 90vw;
    padding: 10px;
    border: 1px solid #1B75BB;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
    border-radius: 10px;
  }

  .book-btn {
    width: 90vw;
  }
}
