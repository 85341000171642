$mainFont: 'Gelasio',
serif;
$fontStyle: normal;

  .remove-review {
    padding: 20px;
    text-align: center;
  }

  .symbol-limit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }

  .remove-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 60vw;
    justify-content: space-around;
  }

  .admin-face {
    width: 60px;
    border-radius: 100%;
    height: 60px;
    object-fit: cover;
  }

  .review-box-admin {
    display: flex;
    justify-content: center;
    position: relative;
    border: 1px solid darkgrey;
    box-sizing: border-box;
    border-radius: 0.31em;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin: 0 5px 5px 15px;
    font-family: $mainFont;
    text-align: center;
  }

  .review-box-admin-width {
    width: 196px;
    height: 196px;
  }

  .review-controls {
    position: absolute;
    cursor: pointer;
    left: 2px;
    top: 2px;
  }

  @media screen and (max-width: 780) {
    .remove-review {
      padding: 20px 40px;
    }
  }

  @media screen and (max-width: 480) {
    .remove-review {
      padding: 20px 20px;
    }
  }