.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin: 3rem 0.5rem;

  .error-color{
    color: red;
  }

  .wrap-login{
    width: 37rem;
    border-radius: 10px;
    background: #f1f1f1;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.15);
    padding: 2rem 5rem;

    .login-form {
      width: 100%;

      .login-form-title {
        display: block;
        font-size: 2.4rem;
        color: #555;
        text-align: center;
      }

      .login-form-text{
        font-size: 1.3rem;
        display: inline-block;
        text-align: center;
      }

      label {
        font-size: 1.25rem;
        color: #555;
        margin-top: 1.1rem;
      }

      .forgot-link{
        color: #999999;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }

      .forgot-link:hover{
        color: #09568d;
      }

      .input-field {
        font-size: 1.1rem;
        width: 100%;
        background: white;
        height: 3.75rem;
        padding: 0 1.25rem;
        border-radius: 5px;
        border: none;
        outline: none;
      }

      .login-form-btn {
        width: 100%;
        margin-top: 2.5rem;
        height: 3.75rem;
        background-color: #333;
        border-radius: 10px;
        font-size: 1.5rem;
        color: #fff;
        transition: all .4s;
        border: none;
      }
      .login-form-btn:hover {
        background-color: #696666;
      }
      .btn-m{
        margin-bottom: 2rem;
      }
    }

    .not-akk{
      font-size: 1rem;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      color: #999999;
      a{
        font-size: 1rem;
        margin-left: 0.62rem;
        color: #1a83ff;
      }
      a:hover {
        color: #09568d;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container-login {

    .wrap-login{
      width: 65%;
      display: flex;
      justify-content: center;
      padding: 3rem 0;

      .login-form {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container-login {

    .wrap-login{
      width: 95%;

      .login-form {
        width: 90%;
      }
    }
  }
}
