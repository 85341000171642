.mySwiper_direction.swiper-container {
  width: 80vw !important;
}

.mySwiper_excursions.swiper-container {
  width: 80vw !important;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper_direction .swiper-slide {
  opacity: 0.5;
}

.mySwiper_direction .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper_direction.swiper-container {
  width: 80vw !important;
}

.mySwiper_excursions.swiper-container {
  width: 80vw !important;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper_direction .swiper-slide {
  opacity: 0.5;
}

.mySwiper_direction .swiper-slide-thumb-active {
  opacity: 1;
}

.tour-header {
  margin-bottom: 15px;
}

.Tour-box {
  display: block;
  font-family: 'Gelasio', serif;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 20px;
  height: auto;
  max-width: 60vw;
}

header.tour-header {
  min-height: 65vh;
  align-items: center;
  justify-content: center;
}

.tour_page_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tour_bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
